<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Editar organigrama</h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        @click="addNodeTop(allNodes)"
        v-if="nodeData.id !== '1' && !upperPersonal"
        >Agregar empelado arriba de este nivel</b-button
      >
      <b-button type="is-primary" @click="addNode()"
        >Agregar empelado debajo de este nivel</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div v-if="upperPersonal">
      <h3 class="form-division">Personal arriba de este nivel</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Nombre del personal"
          expanded
          label-position="inside"
          :type="upperPersonal.error == true ? 'is-danger' : ''"
        >
          <b-autocomplete
            v-model="upperPersonal.name"
            :data="getFilteredEmployees(upperPersonal.name)"
            placeholder="Ejemplo: Pedro Rogríguez"
            icon="card-account-details-outline"
            field="fullName"
            open-on-focus
            :clearable="false"
            keep-first
            @select="(option) => fillEmployeeInformation(option)"
            required
          >
            <template #empty>No se encontro personal</template>
          </b-autocomplete>
        </b-field>
        <b-field
          label="Puesto"
          expanded
          label-position="inside"
          :type="upperPersonal.error == true ? 'is-danger' : ''"
        >
          <b-input
            placeholder="Ejemplo: CTO"
            type="text"
            icon="account-hard-hat"
            v-model="upperPersonal.title"
            required
          ></b-input>
        </b-field>
      </b-field>

      <div class="special-margin">
        <v-swatches
          v-model="upperPersonal.color"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>
    </div>

    <h3 class="form-division">Información del personal</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre del personal"
        expanded
        label-position="inside"
        :type="nodeData.error == true ? 'is-danger' : ''"
      >
        <b-autocomplete
          v-model="nodeData.name"
          :data="getFilteredEmployees(nodeData.name)"
          placeholder="Ejemplo: Pedro Rogríguez"
          icon="card-account-details-outline"
          field="fullName"
          open-on-focus
          :clearable="false"
          keep-first
          @select="(option) => fillEmployeeInformation(option)"
          required
        >
          <template #empty>No se encontro personal</template>
        </b-autocomplete>
      </b-field>
      <b-field
        label="Puesto"
        expanded
        label-position="inside"
        :type="nodeData.error == true ? 'is-danger' : ''"
      >
        <b-input
          placeholder="Ejemplo: CTO"
          type="text"
          icon="account-hard-hat"
          v-model="nodeData.title"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="special-margin">
      <v-swatches
        v-model="nodeData.color"
        show-fallback
        popover-x="left"
      ></v-swatches>
    </div>

    <h3
      class="form-division"
      v-if="nodeData.children && nodeData.children.length > 0"
    >
      Personal debajo de este nivel
    </h3>

    <div
      class="edit-organization-single-employee special-margin"
      v-for="(item, index) in nodeData.children"
      :key="index"
    >
      <b-field grouped class="edit-board-modal-name special-margin">
        <b-field
          label="Nombre del personal"
          expanded
          label-position="inside"
          :type="item.error == true ? 'is-danger' : ''"
        >
          <b-autocomplete
            v-model="item.name"
            :data="getFilteredEmployees(item.name)"
            placeholder="Ejemplo: Pedro Rogríguez"
            icon="card-account-details-outline"
            field="fullName"
            open-on-focus
            :clearable="false"
            keep-first
            @select="(value) => fillEmployeeInformationChild(value, item)"
            required
          >
            <template #empty>No se encontro personal</template>
          </b-autocomplete>
        </b-field>
        <b-field
          label="Puesto"
          expanded
          label-position="inside"
          :type="item.error == true ? 'is-danger' : ''"
        >
          <b-input
            placeholder="Ejemplo: CTO"
            type="text"
            icon="account-hard-hat"
            v-model="item.title"
            required
          ></b-input>
        </b-field>
      </b-field>

      <div class="special-margin">
        <v-swatches
          v-model="item.color"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveChanges()"
        >Guardar cambios</b-button
      >
      <b-button
        type="is-danger"
        @click="
          startDelete(
            'danger',
            deleteEmployee,
            'personal del organigrama',
            'el personal del orgranigrama'
          )
        "
        class="cancel-button"
        v-if="nodeData.id !== '1'"
        >Borrar personal del organigrama</b-button
      >
      <b-button
        type="is-danger"
        @click="closeModal()"
        v-bind:class="{ 'cancel-button': nodeData.id === '1' }"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";
import { openConfirmationModalError } from "../../../utils/component-fns";
import VSwatches from "vue-swatches";
export default {
  name: "EditOrganizationBoard",
  props: ["nodeData", "lastId", "allNodes"],
  components: { VSwatches },
  data() {
    return {
      upperPersonal: null,
      lastIdState: this.lastId,
      globalError: false,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      EventBus.$emit("updateOrganizationChart");
      this.$emit("close");
    },
    getFilteredEmployees(name) {
      return this.employees.filter((singleEmployee) => {
        if (singleEmployee.fullName && name) {
          return (
            singleEmployee.fullName
              .toString()
              .toLowerCase()
              .indexOf(name.toLowerCase()) >= 0
          );
        }
        return false;
      });
    },
    addNode() {
      this.lastIdState++;
      if (!this.nodeData.children) {
        this.nodeData.children = [
          {
            id: this.lastIdState.toString(),
            name: "",
            title: "",
            color: "#D9534F",
            children: [],
            error: false,
          },
        ];
      } else {
        this.nodeData.children.push({
          id: this.lastIdState.toString(),
          name: "",
          title: "",
          color: "#D9534F",
          children: [],
          error: false,
        });
      }
    },
    /**
     *
     * @desc Adds a node to the top of the current node
     */
    addNodeTop(current) {
      let foundNode = false;
      for (const [childrenIndex, children] of current.children.entries()) {
        if (children.id === this.nodeData.id) {
          this.lastIdState++;
          const newNode = {
            id: this.lastIdState.toString(),
            name: "",
            title: "",
            color: "#D9534F",
            children: [...current.children],
            error: false,
          };
          current.children[childrenIndex] = newNode;
          this.upperPersonal = current.children[childrenIndex];
          foundNode = true;
          break;
        }
      }

      if (!foundNode) {
        for (const children of current.children) {
          this.addNodeTop(children);
        }
      }
    },
    /**
     *
     * @desc Delete node from organization board
     */
    deleteNode(current, nodeToDeleted) {
      let deletedNode = false;
      for (const [childrenIndex, children] of current.children.entries()) {
        if (children.id === nodeToDeleted.id) {
          const removedChildren = current.children[childrenIndex].children;
          current.children.splice(childrenIndex, 1, ...removedChildren);
          deletedNode = true;
          break;
        }
      }

      if (!deletedNode) {
        for (const children of current.children) {
          this.deleteNode(children, nodeToDeleted);
        }
      }
    },
    /**
     *
     * @desc Check all chldrens for errors
     */
    validateNodes(current) {
      if (!current.name || !current.title) {
        this.globalError = true;
        current.error = true;
      } else {
        current.error = false;
      }
      for (const children of current.children) {
        this.validateNodes(children);
      }
    },
    async saveChanges() {
      this.globalError = false;
      this.validateNodes(this.nodeData);
      if (!this.globalError) {
        const result = await this.$store.dispatch("SAVEORGANIZATIONBOARD", {
          organizationBoard: JSON.stringify(this.allNodes),
          lastOrganizationBoardNumber: this.lastIdState,
        });
        if (result === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente el organigrama",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el organigrama en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Por favor valide que los datos son correctos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startDelete(type, functionOnConfirm, name, deleteMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalError.call(
        this,
        type,
        functionOnConfirm,
        name,
        deleteMessage
      );
    },
    async deleteEmployee() {
      this.globalError = false;
      if (!this.globalError) {
        this.deleteNode(this.allNodes, this.nodeData);
        const result = await this.$store.dispatch("SAVEORGANIZATIONBOARD", {
          organizationBoard: JSON.stringify(this.allNodes),
          lastOrganizationBoardNumber: this.lastIdState,
        });
        if (result === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borro exitosamente el personal del organigrama",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al borrar el personal del organigrama en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
          });
        }
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Por favor valide que los datos son correctos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
        });
      }
    },
    fillEmployeeInformation(option) {
      for (const singleEmployee of this.employees) {
        if (option.fullName === singleEmployee.fullName) {
          this.nodeData.name = singleEmployee.fullName;
          this.nodeData.title = singleEmployee.PUESTO;
        }
      }
    },
    fillEmployeeInformationChild(event, item) {
      for (const singleEmployee of this.employees) {
        if (event === singleEmployee.fullName) {
          item.name = singleEmployee.fullName;
          item.title = singleEmployee.PUESTO;
        }
      }
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        const employeeWithProps = { ...singleEmployee };
        employeeWithProps.fullName = `${singleEmployee.NOMBRE}  ${singleEmployee.PATERNO} ${singleEmployee.MATERNO}`;
        return employeeWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../style/EditOrganizationBoard.css";
@import "../../Global/style/Global.css";
</style>
