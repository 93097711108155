<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de horas extra: <b>{{ employeeExtraHours.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="employeeExtraHours"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="employeeExtraHours"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.OBSERVACIONES}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="OBSERVACIONES"
      >
        <template #empty>
          <div class="has-text-centered">No hay horas extras</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <span
              v-if="checkIfValid(props.row[column.field]) && column.money"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="checkIfValid(props.row[column.field]) && !column.money"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="startDeleteExtraHour(props.row)"
                    v-if="props.row.STATUS !== 'PA'"
                    >Borrar horas extra</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { openConfirmationModalDanger } from "@/utils/component-fns";

export default {
  name: "EmployeeExtraHours",
  props: ["employeeInformation"],
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "OBSERVACIONES",
          label: "Observaciones",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formattedDate",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "CANTIDAD",
          label: "Horas",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      refreshLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    startDeleteExtraHour(extraHoursInformation) {
      this.startCancelEvent(
        "danger",
        () => {
          this.deleteEmployeeExtraHours(extraHoursInformation);
        },
        "Borras horas extra"
      );
    },
    async deleteEmployeeExtraHours(extraHoursInformation) {
      try {
        let response = await this.$store.dispatch("DELETEEMPLOYEEEXTRAHOURS", {
          extraHour: extraHoursInformation._id,
          employeeId: this.employeeInformation._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borraron exitosamente las horas extra",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al borrar las horas extra en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al borrar las horas extra en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        const employeeWithProps = { ...singleEmployee };

        if (singleEmployee.TIPO === "CO") {
          employeeWithProps.TIPO_FOR = "Comprador";
        } else if (singleEmployee.TIPO === "VE") {
          employeeWithProps.TIPO_FOR = "Vendedor";
        } else if (singleEmployee.TIPO === "VC") {
          employeeWithProps.TIPO_FOR = "Vendedor/Comprador";
        }

        return employeeWithProps;
      });
    },
    employeeExtraHours() {
      if (!this.employeeInformation || !this.employeeInformation.HORAS_EXTRA) {
        return [];
      }

      return this.employeeInformation.HORAS_EXTRA.map((singleExtraHour) => {
        const singleExtraHourWithProps = { ...singleExtraHour };

        singleExtraHourWithProps.formattedDate = moment(
          singleExtraHour.FECHA
        ).format("DD-MM-YYYY HH:mm");

        return singleExtraHourWithProps;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
