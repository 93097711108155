<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar retardo</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Horas extra del día martes"
          type="text"
          icon="note"
          v-model="newEmployeeMissingHours.OBSERVACIONES"
          required
        ></b-input>
      </b-field>
      <b-field label="Fecha (Requerido)" expanded label-position="inside">
        <b-datepicker
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Fecha"
          icon="calendar-today"
          v-model="newEmployeeMissingHours.FECHA"
          trap-focus
          required
        >
        </b-datepicker>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveEmployeeMissingHours()"
        >Guardar retardo</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { validateFields, checkValidationErrors } from "@/utils/fns";
import { fillObjectForm } from "@/utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddEmployeeLateHoursModal",
  props: ["employeeInformation"],
  directives: { cleave },
  components: {},
  data() {
    return {
      moment: moment,
      newEmployeeMissingHours: {
        OBSERVACIONES: "",
        FECHA: new Date(),
      },
      masks: Masks,
      errors: {
        CANTIDAD: false,
        FECHA: false,
      },
    };
  },
  async mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveEmployeeMissingHours() {
      try {
        this.errors = validateFields(this.newEmployeeMissingHours, this.errors);
        if (checkValidationErrors(this.errors)) {
          let response = await this.$store.dispatch("SAVEEMPLOYEELATEHOURS", {
            lateHours: this.newEmployeeMissingHours,
            employeeId: this.employeeInformation._id,
          });
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el retardo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el retardo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el retardo en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el retardo en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../style/AddEmployeeModal.css";
@import "../../Global/style/Global.css";
</style>
