var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[(!_vm.employeeInformationInventory)?_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Agregar inventario de personal ")]):_vm._e(),(!_vm.employeeInformationInventory)?_c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])]):_vm._e(),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Descripción del artículo (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.DESCRIPCION },"message":{
        'La descripción del artículo no es valida': _vm.errors.DESCRIPCION,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Botas","type":"text","icon":"hammer","required":""},model:{value:(_vm.newEmployeeInventory.DESCRIPCION),callback:function ($$v) {_vm.$set(_vm.newEmployeeInventory, "DESCRIPCION", $$v)},expression:"newEmployeeInventory.DESCRIPCION"}})],1),_c('b-field',{attrs:{"label":"Precio (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.PRECIO },"message":{
        'El precio no es valido': _vm.errors.PRECIO,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":5,"placeholder":"Ejemplo: 2000","required":true,"disabled":false},model:{value:(_vm.newEmployeeInventory.PRECIO),callback:function ($$v) {_vm.$set(_vm.newEmployeeInventory, "PRECIO", $$v)},expression:"newEmployeeInventory.PRECIO"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Cantidad (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.CANTIDAD },"message":{
        'La cantidas no es valida': _vm.errors.CANTIDAD,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 3","type":"number","icon":"numeric-1","required":""},model:{value:(_vm.newEmployeeInventory.CANTIDAD),callback:function ($$v) {_vm.$set(_vm.newEmployeeInventory, "CANTIDAD", $$v)},expression:"newEmployeeInventory.CANTIDAD"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Observaciones (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: García","type":"text","icon":"note","required":""},model:{value:(_vm.newEmployeeInventory.OBSERVACIONES),callback:function ($$v) {_vm.$set(_vm.newEmployeeInventory, "OBSERVACIONES", $$v)},expression:"newEmployeeInventory.OBSERVACIONES"}})],1),_c('b-field',{attrs:{"label":"Fecha (Requerido)","expanded":"","label-position":"inside"}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de inicio","icon":"calendar-today","trap-focus":"","required":""},model:{value:(_vm.newEmployeeInventory.FECHA_INVENTARIO),callback:function ($$v) {_vm.$set(_vm.newEmployeeInventory, "FECHA_INVENTARIO", $$v)},expression:"newEmployeeInventory.FECHA_INVENTARIO"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Personal (Requerido)","expanded":"","label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Vendedor","expanded":"","icon":"account-hard-hat","required":""},model:{value:(_vm.newEmployeeInventory.PERSONAL),callback:function ($$v) {_vm.$set(_vm.newEmployeeInventory, "PERSONAL", $$v)},expression:"newEmployeeInventory.PERSONAL"}},[_c('option',{domProps:{"value":undefined}},[_vm._v("Seleccione nombre de personal")]),_vm._l((_vm.employees),function(singleEmployee){return _c('option',{key:singleEmployee._id,domProps:{"value":singleEmployee._id}},[_vm._v(" "+_vm._s(singleEmployee.NOMBRE)+" "+_vm._s(singleEmployee.PATERNO)+" "+_vm._s(singleEmployee.MATERNO)+" ")])})],2)],1)],1),(_vm.employeeInformationInventory)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveEmployeeInformation(1)}}},[_vm._v(" Actualizar inventario del personal ")])],1):_vm._e(),(!_vm.employeeInformationInventory)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveEmployeeInformation(0)}}},[_vm._v("Guardar inventario del personal")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }