<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls">
      <div class="control">
        <b-button type="is-primary" @click="addEmployeeInventory()"
          >Agregar artículo de inventario de personal</b-button
        >
      </div>
      <div class="control">
        <b-button type="is-primary" @click="confirmDeleteEmployeesInventory()"
          >Borrar artículo de inventario de personal</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de artículos: <b>{{ employeesInventory.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="employeesInventory"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="employees"
        :checked-rows.sync="checkedEmployeesInventory"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.DESCRIPCION}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        checkable
        default-sort="DESCRIPCION"
      >
        <template #empty>
          <div class="has-text-centered">No hay artículos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p v-if="column.money && checkIfValid(props.row[column.field])">
              {{ props.row[column.field] | money("MXN", 2) }}
            </p>
            <p v-if="!column.money && checkIfValid(props.row[column.field])">
              {{ props.row[column.field] }}
            </p>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddEmployeeInventoryModal
                  :employeeInformationInventory="props.row"
                />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import AddEmployeeInventoryModal from "./AddEmployeeInventoryModal";

export default {
  name: "EmployeesInventoryList",
  components: {
    AddEmployeeInventoryModal,
  },
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedEmployeesInventory: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PRECIO",
          label: "Precio",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "OBSERVACIONES",
          label: "Observaciones",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formattedDate",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PERSONAL_FOR",
          label: "Personal",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETEMPLOYEESINVENTORY"));
      allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));

      await Promise.all(allPromises);
    },
    addEmployeeInventory() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddEmployeeInventoryModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmDeleteEmployeesInventory() {
      if (this.checkedEmployeesInventory.length > 0) {
        this.reloadInformation();
        this.$store
          .dispatch("DELETEMPLOYEESINVENTORY", {
            deleteEmployeesInventory: this.checkedEmployeesInventory,
          })
          .then((response) => {
            if (response === "Success") {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message:
                  "Se borrarón exitosamente los artículos del inventario del personal seleccionados",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al borrar los artículos del inventario del personal en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay artículos del inventario del personal seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        const employeeWithProps = { ...singleEmployee };

        if (singleEmployee.TIPO === "CO") {
          employeeWithProps.TIPO_FOR = "Comprador";
        } else if (singleEmployee.TIPO === "VE") {
          employeeWithProps.TIPO_FOR = "Vendedor";
        } else if (singleEmployee.TIPO === "VC") {
          employeeWithProps.TIPO_FOR = "Vendedor/Comprador";
        }
        return employeeWithProps;
      });
    },
    employeesInventory() {
      return this.$store.getters.EMPLOYEESINVENTORY.map(
        (singleEmployeeInventory) => {
          const employeeInventoryWithProps = { ...singleEmployeeInventory };

          employeeInventoryWithProps.formattedDate = moment(
            singleEmployeeInventory.FECHA_INVENTARIO
          ).format("DD-MM-YYYY HH:mm");

          employeeInventoryWithProps.PERSONAL_FOR = `${singleEmployeeInventory.PERSONAL.NOMBRE} ${singleEmployeeInventory.PERSONAL.PATERNO}
            ${singleEmployeeInventory.PERSONAL.MATERNO}`;

          employeeInventoryWithProps.TOTAL = String(
            singleEmployeeInventory.CANTIDAD * singleEmployeeInventory.PRECIO
          );

          return employeeInventoryWithProps;
        }
      );
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
