<template>
  <div class="container organization-board-main-container">
    <b-field grouped group-multiline class="organization-board-controls">
      <b-button
        type="is-primary"
        @click="
          startAction(
            'danger',
            restartOrganizationBoard,
            'el organigrama',
            'el oganigrama'
          )
        "
        >Borrar organigrama</b-button
      >
    </b-field>
    <organization-chart
      :datasource="organizationBoard"
      @node-click="openEditOrganizationBoard"
      :zoom="false"
      :key="componentKey"
    >
      <template slot-scope="{ nodeData }">
        <div
          :style="{
            background: nodeData.color ? nodeData.color : defaultColor,
            color: 'white',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            padding: '.1rem',
          }"
        >
          <p
            :style="{
              margin: '0',
            }"
          >
            {{ nodeData.name }}
          </p>
        </div>
        <div
          :style="{
            background: 'white',
            color: 'black',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            padding: '.1rem',
            border: '1px solid white',
            borderColor: nodeData.color ? nodeData.color : defaultColor,
          }"
        >
          <p
            :style="{
              margin: '0',
            }"
          >
            {{ nodeData.title }}
          </p>
        </div>
      </template>
    </organization-chart>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import EditOrganizationBoard from "./EditOrganizationBoard";
import { openConfirmationModalError } from "../../../utils/component-fns";

export default {
  name: "OrganizationBoard",
  components: {
    OrganizationChart,
  },
  data() {
    return {
      defaultColor: "rgba(217,83,79,.8)",
      organizationBoardData: {},
      componentKey: 0,
    };
  },
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));

      await Promise.all(allPromises);
    },
    openEditOrganizationBoard(nodeData) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EditOrganizationBoard,
        props: {
          nodeData: nodeData,
          lastId: this.lastOrganizationBoardNumber,
          allNodes: this.organizationBoard,
        },
        // Force rerendering of component
        onCancel: () => {
          this.componentKey += 1;
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    restartOrganizationBoard() {
      this.$store.commit("RESTART_ORGANIZATION_BOARD");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startAction(type, functionOnConfirm, name, deleteMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalError.call(
        this,
        type,
        functionOnConfirm,
        name,
        deleteMessage
      );
    },
  },
  computed: {
    organizationBoard() {
      return this.$store.getters.ORGANIZATIONBOARD;
    },
    lastOrganizationBoardNumber() {
      return this.$store.getters.LASTORGANIZATIONBOARDNUMBER;
    },
  },
  mounted() {
    // Force rerendering of component
    EventBus.$on("updateOrganizationChart", () => {
      this.componentKey += 1;
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  watch: {},
};
</script>

<style scoped>
@import "../style/OrganizationBoard.css";
@import "../../Global/style/Global.css";
</style>
