<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls">
      <div class="control">
        <b-button type="is-primary" @click="addEmployee()"
          >Agregar personal</b-button
        >
      </div>
      <div class="control">
        <b-button type="is-primary" @click="confirmDeleteEmployees()"
          >Borrar personal</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de personal: <b>{{ employees.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="employees"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="employees"
        :checked-rows.sync="checkedEmployees"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CVE_PERS}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        checkable
        default-sort="NOMBRE"
      >
        <template #empty>
          <div class="has-text-centered">No hay personal</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p>{{ props.row[column.field] }}</p>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddEmployeeModal :employeeInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import AddEmployeeModal from "./AddEmployeeModal";
export default {
  name: "EmployeesList",
  components: {
    AddEmployeeModal,
  },
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedEmployees: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NOMBRE",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PATERNO",
          label: "Apellido paterno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "MATERNO",
          label: "Apellido materno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedBirthDate",
          label: "Fecha de nacimiento",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO_FOR",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TEL_MOVIL",
          label: "Teléfono",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL",
          label: "Correo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC",
          label: "RFC",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CURP",
          label: "Curp",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PUESTO",
          label: "Puesto",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));

      await Promise.all(allPromises);
    },
    addEmployee() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddEmployeeModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmDeleteEmployees() {
      if (this.checkedEmployees.length > 0) {
        this.reloadInformation();
        this.$store
          .dispatch("DELETEMPLOYEES", {
            deleteEmployees: this.checkedEmployees,
          })
          .then((response) => {
            if (response == "Success") {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se borrarón exitosamente los empleados seleccionados",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al borrar los empleados en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay empleados seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        const employeeWithProps = { ...singleEmployee };

        if (singleEmployee.TIPO === "CO") {
          employeeWithProps.TIPO_FOR = "Comprador";
        } else if (singleEmployee.TIPO === "VE") {
          employeeWithProps.TIPO_FOR = "Vendedor";
        } else if (singleEmployee.TIPO === "VC") {
          employeeWithProps.TIPO_FOR = "Vendedor/Comprador";
        }

        employeeWithProps.formatedBirthDate = "N/A";
        if (singleEmployee.FECHA_NACIMIENTO) {
          employeeWithProps.formatedBirthDate = moment(
            singleEmployee.FECHA_NACIMIENTO
          ).format("DD-MM-YYYY");
        }

        return employeeWithProps;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
