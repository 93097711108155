<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div class="table-header">
      Cantidad de personal: <b>{{ employees.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="employees"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="employees"
        :checked-rows.sync="checkedEmployees"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CVE_PERS}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="NOMBRE"
      >
        <template #empty>
          <div class="has-text-centered">No hay personal</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <span
              v-if="checkIfValid(props.row[column.field]) && column.money"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="checkIfValid(props.row[column.field]) && !column.money"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="openEmployeeLoans(props.row)"
                    >Ver horas extra</b-button
                  >
                  <b-button
                    type="is-primary"
                    @click="openAddEmployeeLoan(props.row)"
                    >Agregar horas extra</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import AddEmployeeExtraHoursModal from "@/modules/HumanResources/components/AddEmployeeExtraHoursModal.vue";
import EmployeeExtraHours from "@/modules/HumanResources/components/EmployeeExtraHours.vue";

export default {
  name: "EmployeeExtraHoursList",
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedEmployees: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NOMBRE",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PATERNO",
          label: "Apellido paterno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "MATERNO",
          label: "Apellido materno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TEL_MOVIL",
          label: "Teléfono",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL",
          label: "Correo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD_HORAS_EXTRA",
          label: "Cantidad de Horas extra",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "HORAS_EXTRA_TOTAL",
          label: "Total de Horas exta",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETEMPLOYEESEXTRAHOUR", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openEmployeeLoans(employeeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EmployeeExtraHours,
        props: {
          employeeInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddEmployeeLoan(employeeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddEmployeeExtraHoursModal,
        props: {
          employeeInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETEMPLOYEESEXTRAHOUR", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        const employeeWithProps = { ...singleEmployee };

        if (singleEmployee.TIPO === "CO") {
          employeeWithProps.TIPO_FOR = "Comprador";
        } else if (singleEmployee.TIPO === "VE") {
          employeeWithProps.TIPO_FOR = "Vendedor";
        } else if (singleEmployee.TIPO === "VC") {
          employeeWithProps.TIPO_FOR = "Vendedor/Comprador";
        }

        employeeWithProps.TIPO_FOR = "Vendedor/Comprador";

        employeeWithProps.CANTIDAD_HORAS_EXTRA = 0;
        employeeWithProps.HORAS_EXTRA_TOTAL = 0;

        if (singleEmployee.HORAS_EXTRA) {
          for (const singleHour of singleEmployee.HORAS_EXTRA) {
            employeeWithProps.CANTIDAD_HORAS_EXTRA += singleHour.CANTIDAD;
          }
        }

        if (
          employeeWithProps.CANTIDAD_HORAS_EXTRA &&
          singleEmployee.PRECIO_HORA_EXTRA
        ) {
          employeeWithProps.HORAS_EXTRA_TOTAL =
            employeeWithProps.CANTIDAD_HORAS_EXTRA *
            singleEmployee.PRECIO_HORA_EXTRA;
        }

        return employeeWithProps;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
