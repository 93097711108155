<template>
  <div class="container add-global-modal-main-container">
    <h1
      class="add-global-modal-main-title"
      v-if="!employeeInformationInventory"
    >
      Agregar inventario de personal
    </h1>

    <div
      v-if="!employeeInformationInventory"
      class="global-form-hint-container"
    >
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción del artículo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.DESCRIPCION }"
        :message="{
          'La descripción del artículo no es valida': errors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Botas"
          type="text"
          icon="hammer"
          v-model="newEmployeeInventory.DESCRIPCION"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Precio (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.PRECIO }"
        :message="{
          'El precio no es valido': errors.PRECIO,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 2000"
          :required="true"
          v-model="newEmployeeInventory.PRECIO"
          :disabled="false"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.CANTIDAD }"
        :message="{
          'La cantidas no es valida': errors.CANTIDAD,
        }"
      >
        <b-input
          placeholder="Ejemplo: 3"
          type="number"
          icon="numeric-1"
          v-model="newEmployeeInventory.CANTIDAD"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: García"
          type="text"
          icon="note"
          v-model="newEmployeeInventory.OBSERVACIONES"
          required
        ></b-input>
      </b-field>
      <b-field label="Fecha (Requerido)" expanded label-position="inside">
        <b-datepicker
          class="global-lists-filters-control"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Fecha de inicio"
          icon="calendar-today"
          v-model="newEmployeeInventory.FECHA_INVENTARIO"
          trap-focus
          required
        >
        </b-datepicker>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Personal (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
      >
        <b-select
          placeholder="Ejemplo: Vendedor"
          expanded
          icon="account-hard-hat"
          v-model="newEmployeeInventory.PERSONAL"
          required
        >
          <option :value="undefined">Seleccione nombre de personal</option>
          <option
            v-for="singleEmployee in employees"
            :key="singleEmployee._id"
            :value="singleEmployee._id"
          >
            {{ singleEmployee.NOMBRE }} {{ singleEmployee.PATERNO }}
            {{ singleEmployee.MATERNO }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="employeeInformationInventory">
      <b-button type="is-success" @click="saveEmployeeInformation(1)">
        Actualizar inventario del personal
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!employeeInformationInventory">
      <b-button type="is-success" @click="saveEmployeeInformation(0)"
        >Guardar inventario del personal</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { validateFields, checkValidationErrors } from "@/utils/fns";
import { fillObjectForm } from "@/utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddEmployeeInventoryModal",
  props: ["employeeInformationInventory"],
  directives: { cleave },
  components: {},
  data() {
    return {
      moment: moment,
      newEmployeeInventory: {
        DESCRIPCION: "",
        PRECIO: 0,
        CANTIDAD: 1,
        OBSERVACIONES: "",
        FECHA_INVENTARIO: new Date(),
        PERSONAL: undefined,
      },
      masks: Masks,
      errors: {
        DESCRIPCION: false,
        PRECIO: false,
        CANTIDAD: false,
        FECHA_INVENTARIO: false,
        PERSONAL: false,
      },
    };
  },
  async mounted() {
    // Fill the article information in form fields
    if (this.employeeInformationInventory) {
      // Fill the form with received information
      fillObjectForm(
        this.employeeInformationInventory,
        this.newEmployeeInventory
      );
      this.newEmployeeInventory._id = this.employeeInformationInventory._id;
      this.newEmployeeInventory.PERSONAL =
        this.employeeInformationInventory.PERSONAL._id;
      this.newEmployeeInventory.FECHA_INVENTARIO = new Date(
        this.newEmployeeInventory.FECHA_INVENTARIO
      );
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveEmployeeInformation(type) {
      try {
        this.errors = validateFields(this.newEmployeeInventory, this.errors);
        if (checkValidationErrors(this.errors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEEMPLOYEEINFORMATION" : "EDITEMPLOYEEINFORMATION",
            this.newEmployeeInventory
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el inventario de personal",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el inventario de personal en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el inventario de personal en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el inventario de personal en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../style/AddEmployeeModal.css";
@import "../../Global/style/Global.css";
</style>
