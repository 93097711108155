var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container organization-board-main-container"},[_c('b-field',{staticClass:"organization-board-controls",attrs:{"grouped":"","group-multiline":""}},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startAction(
          'danger',
          _vm.restartOrganizationBoard,
          'el organigrama',
          'el oganigrama'
        )}}},[_vm._v("Borrar organigrama")])],1),_c('organization-chart',{key:_vm.componentKey,attrs:{"datasource":_vm.organizationBoard,"zoom":false},on:{"node-click":_vm.openEditOrganizationBoard},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var nodeData = ref.nodeData;
return [_c('div',{style:({
          background: nodeData.color ? nodeData.color : _vm.defaultColor,
          color: 'white',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          padding: '.1rem',
        })},[_c('p',{style:({
            margin: '0',
          })},[_vm._v(" "+_vm._s(nodeData.name)+" ")])]),_c('div',{style:({
          background: 'white',
          color: 'black',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          padding: '.1rem',
          border: '1px solid white',
          borderColor: nodeData.color ? nodeData.color : _vm.defaultColor,
        })},[_c('p',{style:({
            margin: '0',
          })},[_vm._v(" "+_vm._s(nodeData.title)+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }