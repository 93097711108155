<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls"> </b-field>
    <div class="table-header">
      Cantidad de vacaciones: <b>{{ employeeVacations.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="employeeVacations"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="employeeVacations"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.OBSERVACIONES}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="OBSERVACIONES"
      >
        <template #empty>
          <div class="has-text-centered">No hay vacaciones</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <span
              v-if="checkIfValid(props.row[column.field]) && column.money"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="checkIfValid(props.row[column.field]) && !column.money"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    v-if="props.row.STATUS !== 'C'"
                    type="is-primary"
                    @click="startDeleteEmployeeVacation(props.row)"
                    >Cancelar vacaciones</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { openConfirmationModalDanger } from "@/utils/component-fns";

export default {
  name: "EmployeeVacations",
  props: ["employeeInformation"],
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "OBSERVACIONES",
          label: "Observaciones",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DIAS",
          label: "Cantidad días",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "statusFormatted",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formattedDate",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    startDeleteEmployeeVacation(vacationInformation) {
      this.startCancelEvent(
        "danger",
        () => {
          this.deleteEmployeeVacation(vacationInformation);
        },
        "Cancelar vacaciones"
      );
    },
    async deleteEmployeeVacation(vacationInformation) {
      try {
        let response = await this.$store.dispatch(
          "DELETEVACATION",
          vacationInformation
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borraron exitosamente las vacaciones",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar las vacaciones en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar las vacaciones en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    employeeVacations() {
      return this.employeeInformation.VACACIONES.map((singleVacation) => {
        const vacationWithProps = { ...singleVacation };

        vacationWithProps.statusFormatted = "Activas";

        if (vacationWithProps.STATUS === "C") {
          vacationWithProps.statusFormatted = "Canceladas";
        }

        vacationWithProps.formattedDate = moment(singleVacation.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );

        return vacationWithProps;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
