<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Personal </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed" v-model="personalTab">
      <b-tab-item
        label="Personal"
        icon="account-hard-hat"
        :visible="companyPaymentPlanModules.humanResources.getPersonal === 'S'"
      >
        <EmployeesList></EmployeesList>
      </b-tab-item>
      <b-tab-item
        label="Vacaciones"
        icon="calendar-blank"
        :visible="
          companyPaymentPlanModules.humanResources.getPersonalVacations === 'S'
        "
      >
        <EmployeeVacationsList></EmployeeVacationsList>
      </b-tab-item>
      <b-tab-item
        label="Asistencia"
        icon="clock"
        :visible="
          companyPaymentPlanModules.humanResources.getPersonalLateHours ===
            'S' ||
          companyPaymentPlanModules.humanResources.getPersonalMissingHours ===
            'S'
        "
      >
        <EmployeeAttendance></EmployeeAttendance>
      </b-tab-item>
      <b-tab-item
        label="Horas extra"
        icon="clock-outline"
        :visible="
          companyPaymentPlanModules.humanResources.getPersonalExtraHours === 'S'
        "
      >
        <EmployeeExtraHoursList></EmployeeExtraHoursList>
      </b-tab-item>
      <b-tab-item
        label="Inventario de personal"
        icon="tools"
        :visible="
          companyPaymentPlanModules.humanResources.getPersonalInventory === 'S'
        "
      >
        <EmployeesInventoryList></EmployeesInventoryList>
      </b-tab-item>
      <b-tab-item
        label="Prestamos"
        icon="cash"
        :visible="
          companyPaymentPlanModules.humanResources.getPersonalLoans === 'S'
        "
      >
        <EmployeesLoansList></EmployeesLoansList>
      </b-tab-item>
      <b-tab-item
        label="Organigrama"
        icon="graph"
        :visible="
          companyPaymentPlanModules.humanResources.getOrganizationBoard === 'S'
        "
      >
        <OrganizationBoard></OrganizationBoard>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import OrganizationBoard from "../components/OrganizationBoard";
import EmployeesList from "../components/EmployeesList";
import EmployeeVacationsList from "../components/EmployeeVacationsList";
import EmployeesInventoryList from "../components/EmployeesInventoryList.vue";
import EmployeesLoansList from "../components/EmployeesLoansList";
import EmployeeExtraHoursList from "../components/EmployeeExtraHoursList";
import EmployeeAttendance from "../components/EmployeeAttendance";
import moment from "moment/moment";

// @ is an alias to /src
export default {
  name: "AllPersonalList",
  components: {
    OrganizationBoard,
    EmployeesList,
    EmployeesInventoryList,
    EmployeesLoansList,
    EmployeeExtraHoursList,
    EmployeeAttendance,
    EmployeeVacationsList,
  },
  data() {
    return {
      personalTab: 0,
    };
  },
  methods: {},
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETEMPLOYEESINVENTORY"));
    allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));
    allPromises.push(
      this.$store.dispatch("GETVACATIONS", {
        startDate: moment().startOf("year").toDate(),
        endDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1
        ),
      })
    );

    await Promise.all(allPromises);
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "personal");
    if (this.companyPaymentPlanModules.humanResources.getPersonal === "N") {
      this.personalTab = 1;
    }
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../style/HumanResources.css";
@import "../../Global/style/Global.css";
</style>
