var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Agregar pago")]),_vm._m(0),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Cantidad (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.CANTIDAD },"message":{
        'La cantidad no es valida': _vm.errors.CANTIDAD,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":5,"placeholder":"Ejemplo: 2000","required":true,"disabled":false},model:{value:(_vm.newEmployeeLoanPayment.CANTIDAD),callback:function ($$v) {_vm.$set(_vm.newEmployeeLoanPayment, "CANTIDAD", $$v)},expression:"newEmployeeLoanPayment.CANTIDAD"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Observaciones (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: García","type":"text","icon":"note","required":""},model:{value:(_vm.newEmployeeLoanPayment.OBSERVACIONES),callback:function ($$v) {_vm.$set(_vm.newEmployeeLoanPayment, "OBSERVACIONES", $$v)},expression:"newEmployeeLoanPayment.OBSERVACIONES"}})],1),_c('b-field',{attrs:{"label":"Fecha (Requerido)","expanded":"","label-position":"inside"}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha","icon":"calendar-today","trap-focus":"","required":""},model:{value:(_vm.newEmployeeLoanPayment.FECHA),callback:function ($$v) {_vm.$set(_vm.newEmployeeLoanPayment, "FECHA", $$v)},expression:"newEmployeeLoanPayment.FECHA"}})],1)],1),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveEmployeeLoanPaymentInformation()}}},[_vm._v("Guardar prestamo")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])])}]

export { render, staticRenderFns }